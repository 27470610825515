<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4"> </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap">No. PR</vs-th>
          <vs-th class="whitespace-no-wrap">Nama Item</vs-th>
          <vs-th class="whitespace-no-wrap">Group</vs-th>
          <vs-th class="whitespace-no-wrap">Satuan</vs-th>
          <vs-th class="whitespace-no-wrap">Qty</vs-th>
          <vs-th class="whitespace-no-wrap">Harga</vs-th>
          <vs-th class="whitespace-no-wrap">Subtotal</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td :data="item.no_pengadaan">{{ item.no_pengadaan }}</vs-td>
            <vs-td :data="item.nama_item_pengadaan">{{ item.nama_item_pengadaan }}</vs-td>
            <vs-td :data="item.group2">{{ item.group2 }}</vs-td>
            <vs-td :data="item.satuan_item_pengadaan">{{ item.satuan_item_pengadaan }}</vs-td>
            <vs-td :data="item.qty">{{ item.qty }}</vs-td>
            <vs-td :data="item.harga">{{ item.harga | idr}}</vs-td>
            <vs-td>{{ item.harga * item.qty_actual | idr}}</vs-td>
            <vs-td :data="item.keterangan_gpv">{{ item.keterangan_gpv }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

  </div>
</template>

<script>
import PengadaanRepository from '@/repositories/procurement/pengadaan-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'

export default {
  name: 'ItemsPoChooser',
  components: {
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    tabHeader () {
      return this.$store.state.proyek.poAdd.tabHeader
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)
      params.id_proyek = this.tabHeader.id_proyek
      params.id_rekanan = this.tabHeader.id_rekanan
      params.group_item = this.tabHeader.jenis_po

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      PengadaanRepository.getItemPr(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
